import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Stack,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import Logo from "../image/sticker.jpeg";
import { fetchAddresses } from "../invoice/productDetails";
import { CSRFFormData } from "./editSrf/csrfForm";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const TermsAndConditon = () => {
  return (
    <div style={{ pageBreakBefore: "always" }}>
      <h6 style={{ textAlign: "center" }}>TERMS & CONDITIONS</h6>
      <h6 style={{ textAlign: "center" }}>
        <u style={{ marginBottom: "0.5em" }}>
          Subject to change / modification without prior notice
        </u>
      </h6>
      <div
        style={{
          textAlign: "justify",
          fontSize: "15px",
          marginTop: "5px",
          lineHeight: "1.2rem",
        }}
      >
        <span style={{ display: "flex", gap: "5px" }}>
          <span>1.</span>
          <span>
            PMLPL follows only National/International testing/ calibration
            methods.PMLPL assumes that the calibartion methods used by the
            laboratory and our best measuring capabilities are acceptable to the
            customer.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>2.</span>
          <span>
            Charges are payable 100%against delivery through cash/cheque in
            favour of Perfect Metrological Lab.P.L.,{" "}
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>3.</span>
          <span>
            Item accessories delivery through cash/cheque in favour of Perfect
            Metrological Lab.P.L.,
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>4.</span>
          <span>
            {" "}
            PMLPL personnel shall take every possible care in handling and /or
            use of customer’s instruments.PMLPL however shall not be liable for
            any financial Damage and or/ legally bound for any damage to
            customer’s instruments during Job execution, and which are
            attributed to the properties specification of instruments. Liability
            of PMLPL shall be restricted to repair of instruments in the event
            of damage During period of custody with PMLPL . Delivery /collection
            through courier shall be the Responsibility of customer /charged as
            per.
          </span>
        </span>

        <span style={{ display: "flex", gap: "5px" }}>
          <span>5.</span>
          <span>
            Instruments, submitted by customer may be returned to customer
            without executing the job under circumstances or exigencies beyond
            control of PMLPL.customer will be briefed by job under circumstance
            or exigencies beyond control of PMLPL.customer will be briefed by
            PMLPL personnel in such events and provided with appropriate advice.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>6.</span>
          <span>
            The customer shall satisfy themselves at the time of accepting
            delivery of the instruments/equipments certificate.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>7.</span>
          <span>
            The customer has to make own arrangements for taking delivery of
            their instruments. Certificate would be dispatch by post whenever
            requested by customer and charged as per
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>8.</span>
          <span>
            As policy, electronic transmission of result is not permitted. in
            special case of urgency, the customer is requested to sumbit such
            written request to PMLPL.while receiving and approving such request
            ,PMLPL shall assume that the customer has Assumed total
            responsibility and risk involved for any deviation in the end
            result.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>9.</span>
          <span>
            Calibration certificate issued by PMLPL is not to be used for any
            legal purpose and shall not be produced in court of law. For any
            disputes decision of the head Of PMLPL shall be final.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>10.</span>
          <span>
            Customer shall collect instruments with in 1 month of job execution.
            if instruments Are not collected within reasonable time and /or
            without satisfactory justification, Additional service charge may be
            levied Decision of head PMLPL shall be final.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>11.</span>
          <span>
            Customer shall recognize that a satisfactory calibration certificate
            in no way implies that the equipment calibrated is approved by
            PMLPL.No part of calibration certificate shall be used by customer
            for promotional or publicity purpose in any way that PMLPL may
            consider misleading.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>12.</span>
          <span>
            As a customer friendly practices A PDCC (Porbable date of job
            completion with certificate) is mentioned in the CRF.Customer may
            therefore enquire about the job Completion only on/after the PDCC
            quoting CRF Number.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>13.</span>
          <span>
            PMLPL shall make delivery of instruments and related certificate to
            customer Representative only on production of customer copy of CRF,
            in absence of which PMLPL personnel may refuse to hand over the
            same.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>14.</span>
          <span>Reprint will be charged as per.</span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>15.</span>
          <span>
            Working days: Monday to Saturday. Business Hours: 9.30 am to 6 pm.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>16.</span>
          <span>
            In case of emergency contact details are: 020-25294247/48.
          </span>
        </span>
        <span style={{ display: "flex", gap: "5px" }}>
          <span>17.</span>
          <span>
            Witness Allowed to Customer : Yes / No ( If Yes , then the customer
            will be allowed in specific lab. & will be related to his instrument
            only. Customer need Customer Signature. to follow lab guidelines to
            enter the lab.)
          </span>
        </span>
      </div>
      <div>
        <table
          style={{ width: "100%", margin: "10px auto" }}
          className="srftable"
        >
          <tr>
            <td style={{height:"15px",border:"none"}}></td>
            <td style={{border:"none"}}></td>
          </tr>
          <tr>
            <td style={{padding:"3px",border:"none",width:"70%"}}></td>
            <td style={{padding:"5px",border:"none"}}><b>Customer Signature</b></td>
          </tr>
        </table>
      </div>
    </div>
  );
};


export default function PrintCSRF() {
  const printComponentRef = React.useRef();
  const params = useParams();
  const [srfInstruments, setSrfInstruments] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  var [allInstruments, setInstruments] = React.useState([]);
  const [letterHead, setLetterHead] = React.useState(true);
  const [contact, setContact] = React.useState(null);
  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [commitedDate, setCommitedDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [clientID, setClientID] = React.useState("");
  const [companyData, setCompanyData] = React.useState({
    companyName: "",
    address: "",
  });
  const [address, setAddress] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [clientArray, setClientArray] = React.useState([]);
  const [srfsData, setSRFsData] = React.useState([]);
  const [newAddressArray, setNewAddressArray] = React.useState([]);

  const [pushArray, setPushArray] = React.useState([]);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [signImg, setSignImg] = React.useState(false);
  const [userId, setUserId] = React.useState({
    createdBy:"",
    approvedBy: "",
  });
  const [userImg, setUserImg] = React.useState({
    createdByImg:"",
    approvedByImg: "",
  });

  const [CSRFformState, setCSRFformState] = React.useState(
    CSRFFormData?.map((item) => ({ answer: "", details: "", ...item }))
  );
  const [quest16, setQuest16] = React.useState({
    reference_1: "",
    reference_2: "",
    reference_3: "",
    reference_4: "",
    laboratory_1: "",
    laboratory_2: "",
    laboratory_3: "",
    laboratory_4: "",
    engineer_1: "",
    engineer_2: "",
    engineer_3: "",
    engineer_4: "",
  });
  const [quest17, setQuest17] = React.useState({
    drop_pickup: false,
    pickup_by_external_provider: false,
    jrpm_send_by_courier: false,
  });
  const [quest15, setQuest15] = React.useState({});
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);

  const getCERFForm = async () => {
    await axiosWithToken
      .get(BASE_URL + "csrfForm")
      .then((res) => {
        const data = res.data?.find((item) => item?.srfId == params.id);
        if (data) {
          const updatedCSRFformState = CSRFFormData?.map((item, index) => {
            const fetchedQuestion = data[`quession${index + 1}`];
            const { answer, details } = fetchedQuestion
              ? JSON.parse(fetchedQuestion)
              : { answer: "", details: "" };
            return {
              ...item,
              answer,
              details,
            };
          });
          setCSRFformState(updatedCSRFformState);

          setQuest15(data?.quession15 ? JSON.parse(data?.quession15) : "");

          const question16 = data?.quession16
            ? JSON.parse(data?.quession16)
            : {};
          setQuest16({
            reference_1: question16.reference_1 || "",
            reference_2: question16.reference_2 || "",
            reference_3: question16.reference_3 || "",
            reference_4: question16.reference_4 || "",
            laboratory_1: question16.laboratory_1 || "",
            laboratory_2: question16.laboratory_2 || "",
            laboratory_3: question16.laboratory_3 || "",
            laboratory_4: question16.laboratory_4 || "",
            engineer_1: question16.engineer_1 || "",
            engineer_2: question16.engineer_2 || "",
            engineer_3: question16.engineer_3 || "",
            engineer_4: question16.engineer_4 || "",
          });

          const question17 = data?.quession17
            ? JSON.parse(data?.quession17)
            : {};
          setQuest17({
            drop_pickup: question17.drop_pickup || false,
            pickup_by_external_provider:
              question17.pickup_by_external_provider || false,
            jrpm_send_by_courier: question17.jrpm_send_by_courier || false,
          });
        }
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  function fetchSrfDetails() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfs?_where=(id,eq,${params.id})`)
      .then((res) => {
        setServiceReqNumber(res.data[0]?.serviceReqNumber);
        setDcDate(res.data[0]?.dcDate);
        setReceiptDate(res.data[0]?.receiptDate);
        setEntryDate(res.data[0]?.entryDate);
        setCommitedDate(res.data[0]?.committedDeliveryDate);
        setDcNumber(res.data[0]?.dcNumber);
        setClientID(res.data[0]?.clientId);
        setAddress(res.data[0]?.address);
        setSRFsData(res.data[0]);
       
      const createdByUserId = res.data[0]?.createdby;
      const approvedByUserId = res.data[0]?.approvedby;
      Promise.all([
        createdByUserId
          ? axiosWithToken.get(BASE_URL + `users/${createdByUserId}`)
          : Promise.resolve(null),
        approvedByUserId
          ? axiosWithToken.get(BASE_URL + `users/${approvedByUserId}`)
          : Promise.resolve(null),
      ])
        .then(([createdByRes, approvedByRes]) => {
          const createdByUserData = createdByRes?.data[0];
          const approvedByUserData = approvedByRes?.data[0];
          const createdByName = createdByUserData?.userName || "";
          const approvedByName = approvedByUserData?.userName || "";
          const createdByImg = createdByUserData?.usersign || "";
          const approvedByImg = approvedByUserData?.usersign || "";
          setUserId((prevState) => ({
            ...prevState,
            createdBy: createdByName,
            approvedBy: approvedByName,
          }));
          setUserImg((prevState) => ({
            ...prevState,
            createdByImg: createdByImg,
            approvedByImg: approvedByImg,
          }));
        })
        .catch((err) => console.error("Error fetching user data:", err));
    })
      
      .catch((err) => {
        console.log("error:", err);
      });
  }

  const getClientId = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients?_where=(id,eq,${clientID})`)
      .then((res) => {
        setCompanyData({
          companyName: res.data[0]?.companyName,
          address: res.data[0]?.address,
        });
        setClientArray(res.data);
        setCompanyName(res.data[0]?.companyName);
        setContact(
          res.data[0]?.contact ? JSON.parse(res.data[0]?.contact)[0] : null
        );
      })
      .catch((error) => {
        console.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(status,eq,1)`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([srfInstruments[i].id, srfInstruments[i].instrumentId]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    // console.log(rows);
  };

  const generatePushArray = (srfInstruments, allInstruments) => {
    const newPushArray = [];

    for (let i = 0; i < srfInstruments.length; i++) {
      newPushArray.push({
        instrumentId: allInstruments.find(
          (element) => element.id === srfInstruments[i].instrumentId
        )?.instrumentName,
        ranges: srfInstruments[i].ranges,
        lc: srfInstruments[i].lc,
        make: srfInstruments[i].make,
        conOfReceipt: srfInstruments[i].conOfReceipt,
        calFrequency: srfInstruments[i].calFrequency,
        accuracy: srfInstruments[i].accuracy,
        model: srfInstruments[i].model,
        serialNumber: srfInstruments[i].serialNumber,
        DUCID: srfInstruments[i].DUCID,
        calPoint: srfInstruments[i].calPoint,
        ConOfDuc: srfInstruments[i].ConOfDuc,
        calFrequency: srfInstruments[i].calFrequency,
      });
    }

    return newPushArray;
  };

  useEffect(() => {
    setPushArray(generatePushArray(srfInstruments, allInstruments));
  }, [srfInstruments, allInstruments]);

  useEffect(() => {
    srfInstrumentsList();
    instrumentsList();
    getCERFForm();
    fetchSrfDetails();
  }, []);

  useEffect(() => {
    getClientId();
  }, [clientID]);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  useEffect(async () => {
    let splitAddress = address?.split(",");
    let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
    setNewAddressArray(newAddressArray);
  }, [address]);

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
    <table  style="width:100%;">
    <tr>
    <td rowspan="5" style="width:25%;border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:center;">
    <img src=${Logo} height="60px" /> 
    </td>
    <td rowspan="5" style="width:40%;1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:center;">
    <b style="font-size:25px;">
        CUSTOMER REQUEST FORM
        </b> 
    </td>          
    </tr>    
    <tr>
        <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
            <b style="font-size:17px;">Format No.</b>
        </td>
        <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
        <span style="font-size:16px;">PE / RFT / 21</span>
        </td>
    </tr>
    <tr>
        <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
            <b style="font-size:17px;">Rev. No.</b>
        </td>
        <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
        <span style="font-size:16px;">00</span>
        </td>
    </tr>
    <tr>
        <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
            <b style="font-size:17px;">Issue Date</b>
        </td>
        <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
        <span style="font-size:16px;">27.12.2023</span>
         </td>
    </tr>
    <tr>
    <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
    <b style="font-size:17px;">Pages</b>
    </td>
    <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;text-align:left">
        <span style="font-size:16px;">${pageRange}</span>
    </td>
    </tr>    
  </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async () => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
    <tr>
        <td style="disply:flex;flex-direction:column;width:50%;text-align: center;font-family: Calibri, sans-serif;line-height:30px;padding:2px 15px;">
        <b style="font-size:17px;">Signature of the Customer Representative</b>
        </td>
         <td style="disply:flex;flex-direction:column;width:50%;text-align: center;font-family: Calibri, sans-serif;line-height:30px;padding:2px 15px;">
         <b style="font-size:17px;">For Perfect Metrological Lab.P.L., Authorized Signatories (QM/TM)</b>
        </td>
        </tr>    
    <tr>
        <td style="disply:flex;flex-direction:column;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
        <div style="border-bottom:1px solid black;padding:2px 15px;">
        <b style="font-size:17px;">Prepared By</b>
        </div>
         <b style="font-size:17px;padding:0px 15px;">${userId?.createdBy}</b>
         ${signImg ? 
          `<div style="padding:5px 15px;height:50px;">
            ${userImg?.createdByImg ? `<img src="${userImg?.createdByImg}" style="width:30%; height:100%;border:none;"/>` : ''}
           </div>`
          : ''}  
         </div>
        </td>
         <td style="disply:flex;flex-direction:column;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
         <div style="border-bottom:1px solid black;padding:2px 15px;">
         <b style="font-size:17px;">Approved By</b>
         </div> 
          <b style="font-size:17px;padding:0px 15px;">${userId?.approvedBy}</b>
          ${signImg ? 
            `<div style="padding:5px 15px;height:50px;">
              ${userImg?.approvedByImg ? `<img src="${userImg?.approvedByImg}" style="width:30%; height:100%;border:none;"/>` : ''}
             </div>`
            : ''}          
        </td>
        </tr>
    </table>
    <table style="width:100%;margin-top:5px;">
    <tr>
        <td style="disply:flex;flex-direction:column;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;padding:2px 15px;font-size:18px;border:1px solid black;">
        <b>Perfect Metrological Lab.Pvt.Ltd.</b><br>
        <span>81/5 Dangat Industrial Estate, Shed No: J - 1, Shivane Industrial Area, Shivane - 411023. </span>
        </td>
        </tr>    
    <tr>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [80, 1, 115, 1],
          filename: `srf_${params.id}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 15.5;
        const borderX = leftMargin;
        const borderY = 75;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 655);

        let image = await generateDynamicHeader(i, totalPages);
        pdf.addImage(image, 0, 0, 595, 80);

        image = await generateDynamicFooter();
        pdf.addImage(image, 0, 720, 595, 100);

        // if (letterHead) {
        //   let image = await generateLetterHeadHeader();
        //   pdf.addImage(image, 0, 0, 595, 60);

        //  let image = await generateLetterHeadFooter();
        //   pdf.addImage(image, 0, 805, 595, 40);
        // }

        // if (i === totalPages) {
        //   const text =
        //     "This SRF is electronically signed and does not required physical signature";
        //   const textWidth =
        //     (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
        //     pdf.internal.scaleFactor;
        //   const pageWidth = pdf.internal.pageSize.getWidth();
        //   const x = (pageWidth - textWidth) / 2;
        //   {
        //     electronicSign && pdf.text(text, x, 750);
        //   }
        // }
      }
      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${companyName}_${serviceReqNumber}.pdf`;
      a.click();
      // pdf.save();
      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="srf-viewsrf_letterhead"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    size="small"
                    id="certificate-certificateviewelectronicsign"
                    options={[
                      { id: 1, label: "With Electronically Signed" },
                      { id: 3, label: "Signature Image" },
                      { id: 2, label: "Without  Electronically Signed" },
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Electronically Signed" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue === "With Electronically Signed" ||
                        newInputValue === ""
                      ) {
                        setElectronicSign(true);
                      } else if (
                        newInputValue === "Without  Electronically Signed"
                      ) {
                        setElectronicSign(false);
                        setSignImg(false);
                      }
                      else if (
                        newInputValue === "Signature Image"
                      ) {
                        setElectronicSign(false);
                        setSignImg(true);
                      }
                    }}
                  />
                </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <br />
      <div className="srf" ref={printComponentRef}>
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            lineHeight: "16px",
          }}
        >
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "13px", paddingBottom: "5px" }}>
            <table
              style={{ width: "95%", margin: "5px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "20%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  CRF No
                </th>
                <td
                  style={{
                    width: "15%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "15%",
                  }}
                >
                  Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "15%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "15%",
                  }}
                >
                  D.C. No
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "15%",
                    borderBottom: "none",
                  }}
                >
                  {dcNumber}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  Customer Name & Address
                </th>
                <td
                colSpan={"5"}
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                  }}
                >
                  {companyName} <br/>
                  {newAddressArray?.[0]?.address}
                </td>
                {/* <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {moment(dcDate).format("DD-MM-YYYY")}
                </td> */}
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Contact Person(User)
                </th>
                <td
                colSpan={"2"}
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                {clientArray[0]?.contact
                    ? JSON.parse(clientArray[0]?.contact)[0]?.contactPersonName
                    : ""}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Contact No
                </th>
                <td colSpan={"2"} style={{ padding: "7px",borderBottom: "none",}}>
                {clientArray[0]?.contact
                    ? JSON.parse(clientArray[0]?.contact)[0]?.contact
                    : ""}
                </td>
              </tr>              
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                  }}
                >Caliabration
                </th>
                <td
                colSpan={"2"}
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    borderLeft:"none"
                  }}
                ></td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                  }}
                >
                  E-mail
                </th>
                <td colSpan={"2"} style={{ padding: "7px", width: "250px" }}>
                {clientArray[0]?.contact
                    ? JSON.parse(clientArray[0]?.contact)[0]?.emailId
                    : ""}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "0px auto", fontSize: "11px" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={11}
                    style={{ padding: "5px", borderBottom: "none" }}
                  >
                    INSTRUMENT DETAILS
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    SI.No
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Description of the Equipment
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Make
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Model
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Serial Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Identification Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Range
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Calibration Points If any
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Physical Status of Instrument
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Requested Due Date
                  </th>
                  <th style={{ padding: "7px" }}>Condition of UUC / Remarks</th>
                </tr>
              </thead>
              {pushArray?.length > 0 ? (
                <tbody>
                  {pushArray?.map((item, index) => {
                    return (
                      <tr style={{ lineHeight: "13px" }} key={index} id="pagbreaksborder">
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.instrumentId}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.make
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.model}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.serialNumber}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.DUCID}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.ranges
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.calPoint}{" "}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.accuracy
                            ? `± ${item?.accuracy
                                ?.replaceAll("||", ", ")
                                ?.replaceAll("|", " to ")
                                ?.replaceAll("#", " ")}`
                            : ""}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.calFrequency}
                        </td>
                        <td style={{ padding: "7px", borderTop: "none" }}>
                          {item?.ConOfDuc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11">No items added</td>
                  </tr>
                </tbody>
              )}
            </table>
            <Box
              sx={{
                width: "95%",
                margin: "10px auto",
                // border: "1px solid black",
                // padding: "5px",
                fontSize: "13px",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <table style={{ border: "none" }}>
                <tbody style={{lineHeight:"1.2rem"}}>
                  {CSRFformState?.map((item, index) => (
                    <tr key={index} style={{pageBreakInside:"avoid"}} id="pagbreaksborder">
                      {!item?.hasPoints && (
                        <td
                          style={{
                            width: "45%",
                            padding: "0px 5px",
                            border: "1px solid black",
                            borderBottom:"none",
                            borderRight:"none",
                            verticalAlign:"top",
                          }}
                        >
                          <Typography variant="span">
                            {item?.question}
                          </Typography>
                        </td>
                      )}

                      {item?.hasPoints && (
                        <td
                          style={{
                            width: "45%",
                            padding: "0px 5px",
                            border: "1px solid black",
                            borderBottom:"none",
                            borderRight:"none",
                            verticalAlign:"top",
                          }}
                        >
                          <Typography variant="span">
                            <b>
                              {item?.question}
                            </b>
                          </Typography>
                          {item?.points?.map((option, index) => (
                            <Typography
                              variant="caption"
                              display="block"
                              key={index}
                            >
                              {option}
                            </Typography>
                          ))}
                        </td>
                      )}
                      <td style={{ border: "1px solid black" ,borderBottom:"none", width:"55%" , verticalAlign:"top",padding:"0px 5px"}}>
                        {item?.options?.map((option, optionIndex) => (
                          <span key={optionIndex}>
                            <Checkbox
                              size="small"
                              sx={{ m: "0px", p: "0px 5px" }}
                              checked={item?.answer === option}
                            />
                            {option}
                          </span>
                        ))}
                      {item?.hasDetails && (
                        <div
                          style={{
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft:"5px"
                          }}
                        >
                          <Typography variant="span">
                            {item?.details}
                          </Typography>
                        </div>
                      )}
                      {item?.hasoptions && (
                        <div
                          style={{
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item?.options1.map((option, optionIndex) => (
                            <span key={optionIndex}>
                              <Checkbox
                                size="small"
                                sx={{ m: "0px", p: "0px 5px" }}
                                checked={item?.details === option}
                              />
                              {option}
                            </span>
                          ))}
                        </div>
                      )}
                      {item?.hasPoints && (
                        <div
                          style={{
                            border: "none",
                            // display: "flex",
                            // alignItems: "center",
                          }}
                        >
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: "100%" }}
                          >
                            <Typography variant="span">
                              Certificate to be issued in the name of:                            
                              {quest15?.details?.split(",")[1]?.trim()}
                            </Typography>
                          </FormControl>
                        </div>
                      )}
                      </td>

                    </tr>
                  ))}
                  <tr id="pagbreaksborder">
                    <td colSpan={2} style={{ border:"1px solid black",borderRight:"none",borderBottom:"none" }}>
                    </td>
                  </tr>
                </tbody>
              </table>
              {TermsAndConditon()}
            </Box>
          </div>
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      <ToastContainer />
      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"SRF"} />
      )}
    </Paper>
  );
}
